<template>
    <b-container fluid class="bg-skyblue">
        <b-navbar variant="faded" type="light">
            <b-navbar-brand tag="div" class="mx-auto">
                <b-img fluid src="/img/title.png" class="img-padding" alt="About Poyosi" title="About Poyosi" />
            </b-navbar-brand>
        </b-navbar>
    </b-container>
</template>

<script>
import { 
    BContainer,
    BNavbar,
    BNavbarBrand,
    BImg,
} from 'bootstrap-vue'

export default {
    components: {
        BContainer,
        BNavbar,
        BNavbarBrand,
        BImg,
    }
}
</script>

<style>
.container,
.container-fluid,
.navbar,
.navbar-brand {
    padding: 0 !important;
}
.img-padding {
    padding: 2vh;
}
.bg-skyblue {
    background-color: #8cddee;
}
</style>
