<template>
    <b-container>
        <b-row>
            <b-col md="6" class="pt-4">
                <div class="youtube">
                    <iframe
                        src="https://www.youtube-nocookie.com/embed/h8dgzQX1jgQ"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen="true">
                    </iframe>
                </div>
            </b-col>
            <b-col md="6" class="pt-4">
                <p class="about">2021年8月にVtuberデビューした、大型新人癒し系マイペースおっとりお姉さんストリーマーです！<br />
                重度の引きこもりで、酒と女（アイドル）とお金が大好きです！</p>

                <p class="about">Vtuberだけどたまに現実にもどってリアルから配信もできちゃう、ヴァーチャルと現実を行ったり来たりしてる系配信者です。<br />
                （※苦手な方は注意）</p>

                <p class="about">活動のメインはゲーム実況と雑談配信！<br />
                Sengoku Gaming所属。<br />
                ライブ配信に遊びに来てね、まってます！</p>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import { 
    BContainer,
    BRow,
    BCol,
} from 'bootstrap-vue'

export default {
    components: {
        BContainer,
        BRow,
        BCol,
    }
}
</script>

<style scoped>
.container {
  padding-left: 0;
  padding-right: 0;
}
.container-fluid {
  padding-left: 0;
  padding-right: 0;
}
.youtube {
    width: 100%;
    aspect-ratio: 16/9;
}
.youtube iframe {
    width: 100%;
    height: 100%;
}
.about {
    font-family: 'Yu Gothic';
    font-size:0.9em;
}
</style>
