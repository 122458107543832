<template>
    <b-container fluid>
        <b-row>
            <b-col>
                <b-img src="/img/top.png" fluid class="top-img" alt="Poyosi Top Image" title="Poyosi Top Image" />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import { 
    BContainer,
    BRow,
    BCol,
    BImg,
} from 'bootstrap-vue'

export default {
    components: {
        BContainer,
        BRow,
        BCol,
        BImg,
    }
}
</script>

<style scoped>
.container {
  padding-left: 0;
  padding-right: 0;
}
.container-fluid {
  padding-left: 0;
  padding-right: 0;
}
.col {
  padding-left: 0;
  padding-right: 0;
}
.top-img {
    width: 100%;
    height: auto;
}
</style>
