<template>
    <b-container>
        <b-row>
            <b-col md="6" class="pt-4">
                <b-link href="https://poyosi.fanbox.cc/" target="_blank">
                    <b-img src="/img/pixivfanboxlink.png" fluid alt="pixivFANBOX" title="pixivFANBOX" />
                </b-link>
            </b-col>
            <b-col md="6" class="pt-4">
                <b-link href="https://poyosi.booth.pm/" target="_blank">
                    <b-img src="/img/boothlink.png" fluid alt="BOOTH" title="BOOTH" />
                </b-link>
            </b-col>

        </b-row>
    </b-container>
</template>

<script>
import { 
    BContainer,
    BRow,
    BCol,
    BImg,
    BLink,
} from 'bootstrap-vue'

export default {
    components: {
        BContainer,
        BRow,
        BCol,
        BImg,
        BLink,
    }
}
</script>

<style scoped>
.container {
    padding-left: 0;
    padding-right: 0;
}
.container-fluid {
  padding-left: 0;
  padding-right: 0;
}
</style>
