<template>
    <b-container>
        <b-row>
            <b-col md="6" class="pt-4">
                <div class="twitch">
                    <iframe
                        src="https://player.twitch.tv/?channel=poyosi&parent=poyosi.net&muted=true"
                        allowfullscreen="true">
                    </iframe>
                </div>
            </b-col>
            <b-col md="6" class="pt-4">
                <div class="twitter">
                    <a class="twitter-timeline" data-width="100%" data-height="100%" data-theme="light" href="https://twitter.com/KPoyox?ref_src=twsrc%5Etfw">Tweets by KPoyox</a>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import { 
    BContainer,
    BRow,
    BCol,
} from 'bootstrap-vue'

export default {
    components: {
        BContainer,
        BRow,
        BCol,
    }
}
</script>

<style scoped>
.container {
    padding-left: 0;
    padding-right: 0;
}
.container-fluid {
  padding-left: 0;
  padding-right: 0;
}
.twitch, .twitter {
    width: 100%;
    aspect-ratio: 16/9;
}
.twitch iframe, .twitter iframe {
    width: 100%;
    height: 100%;
}
</style>
