<template>
  <div id="app">
    <Navbar />
    <MainContainer />
    <Footer />
    <div class="box_btn_top" v-if="show" @click="scrollTop">
      <img id="btn_top" src="/img/button_top2.png" title="ページトップへ移動" alt="ページトップへ移動" />
    </div>
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue'
import MainContainer from './components/MainContainer.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  data() {
    return {
      show: false,
    };
  },
  components: {
    Navbar,
    MainContainer,
    Footer,
  },
  mounted() {
    window.addEventListener('scroll', () => {
      this.show = (window.scrollY > 200);
    });
  },
  methods: {
    scrollTop: function() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
}


</script>

<style>
#app {
  font-family: 'Yu Gothic', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.container {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.container-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.box_btn_top {
  position: fixed;
  right: 3vw;
  bottom: 3vh;
  width: 3vw;
  height: auto;
}
#btn_top {
  max-width: 40px;
  min-width: 35px;
  width: 100%;
  height: auto;
  cursor: pointer;
}
</style>
