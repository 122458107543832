<template>
    <section>
        <MainContainerTopImage />
        <MainContainerFirstContents />
        <MainContainerSlideShow />
        <MainContainerSecondContents />
        <Main-container-third-contents />
    </section>
</template>

<script>
import MainContainerTopImage from '@/components/MainContainerTopImage'
import MainContainerFirstContents from '@/components/MainContainerFirstContents.vue'
import MainContainerSlideShow from '@/components/MainContainerSlideShow.vue'
import MainContainerSecondContents from '@/components/MainContainerSecondContents.vue'
import MainContainerThirdContents from '@/components/MainContainerThirdContents.vue'

export default {
  components: {
    MainContainerTopImage,
    MainContainerFirstContents,
    MainContainerSlideShow,
    MainContainerSecondContents,
    MainContainerThirdContents,
  }
}
</script>

<style scoped>
.container {
  padding-left: 0;
  padding-right: 0;
}
.container-fluid {
  padding-left: 0;
  padding-right: 0;
}
</style>
